import React from 'react';

import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import FormHandleControl from '../../../../components/FormHandleControl';
import FormSelect from '../../../../components/FormSelect';
import PaperWrapper from '../../../../components/PaperWrapper';
import { useFetchDefaultFiltersData } from '../../../../hooks/useFetchDefaultFiltersData';
import { useFetchPreInscriptionSchoolFiltersData } from '../../../../hooks/useFetchPreInscriptionSchoolFiltersData';
import { useFilters } from '../../../../hooks/useFilters';
import { IFilterList } from '../../../../types/filter-list.type';
import { IFilters } from '../../types';

const formFieldOptions: SxProps = { m: 1, width: 250 };

const PreInscriptionSchoolFilters = React.memo(
  ({ onSubmit, onExport, isSubmitProcess, isExportProcess }: IFilterList<IFilters>) => {
    const { t } = useTranslation();

    const { schoolYears, isLoading } = useFetchPreInscriptionSchoolFiltersData();

    const defaultFilters = useFetchDefaultFiltersData<IFilters>('preInscriptionSchool');

    const { filters, handleSelectChange } = useFilters<IFilters>(defaultFilters);

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();

      if (isLoading) {
        return;
      }

      onSubmit(filters);
    };

    return (
      <PaperWrapper isLoading={isLoading}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} direction="row" alignItems="flex-start">
            <FormSelect
              label={t('general.school_year')}
              name="schoolYear"
              value={filters.schoolYear}
              items={schoolYears}
              onChange={handleSelectChange}
              controlSx={formFieldOptions}
            />
          </Grid>

          <FormHandleControl
            isSubmitProcess={isSubmitProcess}
            isExportProcess={isExportProcess}
            onExport={() => onExport(filters)}
          />
        </form>
      </PaperWrapper>
    );
  },
);

export default PreInscriptionSchoolFilters;
