import React from 'react';

import { TableCell, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { IRegistrationType } from '../../../../types/registration-type';
import { ISummaryStatistics } from '../../../../types/summary.type';

interface IProps {
  statistics: ISummaryStatistics;
  type: string;
  groupCode?: string;
}

const sxCell: SxProps<Theme> = { textAlign: 'center' };

const PreInscriptionSummaryListItem = React.memo(({ groupCode, type, statistics }: IProps) => {
  const { t } = useTranslation();
  const { responseDa, afterRapo, responseRapo } = statistics;

  return (
    <TableRow>
      {type === IRegistrationType.new ? <TableCell rowSpan={6}>{groupCode}</TableCell> : null}

      <TableCell rowSpan={2}>{t(`general.${type}`)}</TableCell>
      <TableCell sx={sxCell}>{responseDa.accepted}</TableCell>
      <TableCell sx={sxCell}>{responseDa.refused}</TableCell>
      <TableCell sx={sxCell}>{responseDa.total}</TableCell>
      <TableCell sx={sxCell}>{afterRapo.accepted}</TableCell>
      <TableCell sx={sxCell}>{afterRapo.refused}</TableCell>
      <TableCell sx={sxCell}>{afterRapo.total}</TableCell>
      <TableCell sx={sxCell}>{responseRapo.accepted}</TableCell>
      <TableCell sx={sxCell}>{responseRapo.refused}</TableCell>
    </TableRow>
  );
});

export default PreInscriptionSummaryListItem;
