import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { IPreInscriptionSummary } from '../../../../types/pre-inscription-summary.type';
import PreInscriptionSummaryListBody from '../PreInscriptionSummaryListBody';
import PreInscriptionSummaryListHead from '../PreInscriptionSummaryListHead';

interface IProps {
  summary: IPreInscriptionSummary[];
}

const PreInscriptionSummaryList = React.memo(({ summary }: IProps) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <PreInscriptionSummaryListHead />
          <PreInscriptionSummaryListBody inscriptions={summary} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

export default PreInscriptionSummaryList;
