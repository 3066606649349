import React from 'react';

import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { CHART_COLOR } from '../../../../constants/chart.constant';
import { useUserFilters } from '../../../../hooks/useUserFilters';
import { IStatisticsEvolutionPeriod } from '../../../../types/statistics.type';
import { DateUtils } from '../../../../utils/date.utils';

interface IProps {
  school: string;
  data: IStatisticsEvolutionPeriod;
}

const StatisticsEvolutionChart = React.memo(({ school, data }: IProps) => {
  const { t } = useTranslation();

  const filters = useUserFilters();
  const labels = [''];

  const getYearByPeriod = React.useCallback(
    (period: 1 | 2) => {
      let year = DateUtils.getCurrentYear();

      if (period === 2) {
        if (filters?.schoolYearComparison?.length) {
          year = +filters.schoolYearComparison;
        } else {
          year -= 1;
        }
      } else if (filters?.schoolYear?.length) {
        year = +filters.schoolYear;
      }

      return `${year}/${year + 1}`;
    },
    [filters],
  );

  const chartData: ChartData<'bar'> = {
    labels,
    datasets: [
      {
        label: t('dashboard.statistics.period', {
          year: getYearByPeriod(1),
        }),
        data: [data.period1],
        backgroundColor: CHART_COLOR.yellow.default,
      },
      {
        label: t('dashboard.statistics.period', {
          year: getYearByPeriod(2),
        }),
        data: [data.period2],
        backgroundColor: CHART_COLOR.green.default,
      },
    ],
  };

  return (
    <Bar
      options={{
        plugins: {
          title: {
            display: true,
            text: t(`school.${school}`),
            align: 'center',
          },
        },
      }}
      data={chartData}
    />
  );
});

export default StatisticsEvolutionChart;
