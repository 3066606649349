import React from 'react';

import { InputLabel, SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { SxProps } from '@mui/system';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import FormDatePicker from '../../../../components/FormDatePicker';
import FormSchoolSelect from '../../../../components/FormSchoolSelect';
import FormSelect from '../../../../components/FormSelect';
import { ISchoolYear } from '../../../../types/school-year.type';
import { ISchool } from '../../../../types/school.type';
import { ISettingsForm } from '../../types';

const formFieldOptions: SxProps = { m: 1, width: 250 };

interface IProps {
  filters: ISettingsForm;
  schools: ISchool[];
  schoolYears: ISchoolYear[];
  handleSelectChange: <T = string>(event: SelectChangeEvent<T>) => void;
  handleDateChange: (name: string, value: Dayjs | null) => void;
}

const SettingsGeneralForm = React.memo(
  ({ schools, schoolYears, filters, handleSelectChange, handleDateChange }: IProps) => {
    const { t } = useTranslation();

    return (
      <Paper sx={{ p: 2 }}>
        <Grid container item md={6} direction="column">
          <FormSchoolSelect
            label={t('settings.school')}
            onChange={handleSelectChange}
            schools={schools}
            value={filters.school}
            controlSx={formFieldOptions}
          />

          <FormSelect
            label={t('settings.school_year')}
            name="schoolYear"
            value={filters.schoolYear}
            items={schoolYears}
            onChange={handleSelectChange}
            gridProps={{
              container: true,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            displayEmpty
            controlSx={formFieldOptions}
          />

          <FormSelect
            label={t('settings.school_year_comparison')}
            name="schoolYearComparison"
            value={filters.schoolYearComparison}
            items={schoolYears}
            onChange={handleSelectChange}
            gridProps={{
              container: true,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            displayEmpty
            controlSx={formFieldOptions}
          />

          <Grid container item justifyContent="space-between" alignItems="center">
            <InputLabel>{t('settings.date_from')}</InputLabel>

            <FormDatePicker
              value={filters.dateFrom}
              onChange={(value) => handleDateChange('dateFrom', value)}
              controlSx={formFieldOptions}
            />
          </Grid>

          <Grid container item justifyContent="space-between" alignItems="center">
            <InputLabel>{t('settings.date_from_comparison')}</InputLabel>

            <FormDatePicker
              value={filters.dateFromComparison}
              onChange={(value) => handleDateChange('dateFromComparison', value)}
              controlSx={formFieldOptions}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  },
);

export default SettingsGeneralForm;
