import React from 'react';

import { SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PRODUCT_TYPE_LIST } from '../../constants/product-type.constant';
import { ControlSx } from '../../types/control.type';
import { IFormSelectItem } from '../../types/form-select-item.type';
import FormSelect from '../FormSelect';

type IProps = ControlSx & {
  value: string;
  name?: string;
  onChange: <T>(event: SelectChangeEvent<T>) => void;
};

const FormProductTypeSelect = React.memo(({ name = 'productType', ...props }: IProps) => {
  const { t } = useTranslation();

  const PRODUCT_TYPES: IFormSelectItem[] = PRODUCT_TYPE_LIST.map((code) => ({
    code,
    name: t(`product_type.${code.toLowerCase()}`),
  }));

  return (
    <FormSelect
      label={t('general.product_type')}
      name={name}
      items={PRODUCT_TYPES}
      useEmptyValue
      {...props}
    />
  );
});

export default FormProductTypeSelect;
