import React, { useEffect, useState } from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { IStaffStatistics } from '../../../../types/staff-statistics.type';
import StaffStatisticsListBody from '../StaffStatisticsListBody';
import StaffStatisticsListFooter from '../StaffStatisticsListFooter';
import StaffStatisticsListHead from '../StaffStatisticsListHead';

interface IProps {
  statistics: IStaffStatistics[];
}

const StaffStatisticsList = React.memo(({ statistics }: IProps) => {
  const [statuses, setStatuses] = useState<string[]>([]);

  useEffect(() => {
    setStatuses(Object.keys(statistics[0].status));
  }, [statistics]);

  return (
    <Grid container mt={3}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <StaffStatisticsListHead statuses={statuses} />
            <StaffStatisticsListBody statistics={statistics} />
            <StaffStatisticsListFooter statistics={statistics} statuses={statuses} />
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
});

export default StaffStatisticsList;
