import React from 'react';

import { Logout, Settings } from '@mui/icons-material';
import { ListItemIcon, Menu, MenuItem, PopoverProps } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router';

import keycloak from '../../../../keycloak';

interface IProps {
  open: boolean;
  anchorEl?: PopoverProps['anchorEl'];
  handleClose: () => void;
}

const UserMenu = React.memo(({ open, anchorEl, handleClose }: IProps) => {
  const { t } = useTranslation();

  const handleLogoutClick = React.useCallback(() => keycloak.logout(), []);

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={handleClose} component={NavLink} to="/settings">
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>

        {t('menu.settings')}
      </MenuItem>

      <Divider />

      <MenuItem onClick={handleLogoutClick}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>

        {t('menu.logout')}
      </MenuItem>
    </Menu>
  );
});

export default UserMenu;
