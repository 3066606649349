import React from 'react';

import { TableCell, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import TableGroupRow from '../../../../components/TableGroupRow';
import { IStaffStatistics } from '../../../../types/staff-statistics.type';

const sxNumberCell: SxProps<Theme> = { fontWeight: 'bold', textAlign: 'center' };

interface IProps {
  item: IStaffStatistics;
}

const StaffStatisticsListGroup = React.memo(({ item }: IProps) => (
  <TableGroupRow groupTitle={item.groupCode} groupRowColSpan={2}>
    {Object.entries(item.status).map(([name, status]) => (
      <TableCell key={`status_${item.groupCode}_${name}`} sx={sxNumberCell}>
        {status}
      </TableCell>
    ))}
    <TableCell sx={sxNumberCell}>{item.total}</TableCell>
  </TableGroupRow>
));

export default StaffStatisticsListGroup;
