import React from 'react';

import { TableBody } from '@mui/material';

import { IStaffStatusEvolution } from '../../../../types/staff-status-evolution.type';
import StaffStatusEvolutionListItem from '../StaffStatusEvolutionListItem';

interface IProps {
  evolution: IStaffStatusEvolution[];
}

const StaffStatusEvolutionListBody = React.memo(({ evolution }: IProps) => (
  <TableBody>
    {evolution.map((item) => (
      <StaffStatusEvolutionListItem key={item.code} evolution={item} />
    ))}
  </TableBody>
));

export default StaffStatusEvolutionListBody;
