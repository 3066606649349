import React from 'react';

import { TableBody } from '@mui/material';

import { SchoolLevelHelper } from '../../../../helpers/school-level.helper';
import { StaffEvolutionHelper } from '../../../../helpers/staff-evolution.helper';
import { IStaffEvolution, IStaffEvolutionGroup } from '../../../../types/staff-evolution.type';
import { StringUtils } from '../../../../utils/string.utils';
import StaffEvolutionListGroup from '../StaffEvolutionListGroup';
import StaffEvolutionListItem from '../StaffEvolutionListItem';

interface IProps {
  evolution: IStaffEvolution[];
  displayProductType?: string;
}

const StaffEvolutionListBody = React.memo(({ evolution, displayProductType }: IProps) => {
  const renderList = React.useMemo(() => {
    if (!evolution?.length) {
      return null;
    }

    const grouped = SchoolLevelHelper.groupByCode<IStaffEvolutionGroup>(evolution);

    return Object.entries(grouped).map(([group, groupedEvolution]) => (
      <React.Fragment key={StringUtils.random(36)}>
        {groupedEvolution.map((item) => (
          <StaffEvolutionListItem
            key={StaffEvolutionHelper.makeKey(item)}
            evolution={item}
            displayProductType={displayProductType}
          />
        ))}

        <StaffEvolutionListGroup
          groupName={group}
          evolution={groupedEvolution}
          displayProductType={displayProductType}
        />
      </React.Fragment>
    ));
  }, [displayProductType, evolution]);

  return <TableBody>{renderList}</TableBody>;
});

export default StaffEvolutionListBody;
