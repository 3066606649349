import React from 'react';

import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import ProgressButton from '../ProgressButton';

interface IProps {
  isSubmitProcess: boolean;
  isExportProcess?: boolean;
  isDisabled?: boolean;
  onExport?: () => void;
  submitTitle?: string;
  exportTitle?: string;
}

const FormHandleControl = React.memo(
  ({
    isSubmitProcess,
    isExportProcess = false,
    onExport,
    isDisabled = false,
    submitTitle,
    exportTitle,
  }: IProps) => {
    const { t } = useTranslation();

    return (
      <Grid container spacing={1} px={1} pt={1}>
        <Grid item>
          <ProgressButton
            title={submitTitle ?? t('general.filter')}
            type="submit"
            isLoading={isSubmitProcess}
            isDisabled={isExportProcess || isDisabled}
          />
        </Grid>

        <Grid item>
          <ProgressButton
            title={exportTitle ?? t('general.export')}
            isLoading={isExportProcess}
            isDisabled={isSubmitProcess || isDisabled}
            onClick={onExport}
          />
        </Grid>
      </Grid>
    );
  },
);

export default FormHandleControl;
