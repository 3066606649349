import React from 'react';

import { TableCell, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import { IProductType } from '../../../../types/product-type.type';
import { IStaffEvolution } from '../../../../types/staff-evolution.type';

interface IProps {
  evolution: IStaffEvolution;
  displayProductType?: string;
}

const sxNumberCell: SxProps<Theme> = { textAlign: 'right' };

const StaffEvolutionListItem = React.memo(({ evolution, displayProductType }: IProps) => (
  <TableRow>
    <TableCell>{evolution.schoolLevel}</TableCell>

    {(!displayProductType?.length || displayProductType === IProductType.COCO) && (
      <>
        <TableCell sx={sxNumberCell}>{evolution.COCO.period1}</TableCell>
        <TableCell sx={sxNumberCell}>{evolution.COCO.period2}</TableCell>
        <TableCell sx={sxNumberCell}>{evolution.COCO.evolution}%</TableCell>
      </>
    )}

    {(!displayProductType?.length || displayProductType === IProductType.DISE) && (
      <>
        <TableCell sx={sxNumberCell}>{evolution.DISE.period1}</TableCell>
        <TableCell sx={sxNumberCell}>{evolution.DISE.period2}</TableCell>
        <TableCell sx={sxNumberCell}>{evolution.DISE.evolution}%</TableCell>
      </>
    )}

    {(!displayProductType?.length || displayProductType === IProductType.CERTIFICATE) && (
      <>
        <TableCell sx={sxNumberCell}>{evolution.CERTIFICATE.period1}</TableCell>
        <TableCell sx={sxNumberCell}>{evolution.CERTIFICATE.period2}</TableCell>
        <TableCell sx={sxNumberCell}>{evolution.CERTIFICATE.evolution}%</TableCell>
      </>
    )}

    {!displayProductType?.length && (
      <>
        <TableCell sx={sxNumberCell}>{evolution.total.period1}</TableCell>
        <TableCell sx={sxNumberCell}>{evolution.total.period2}</TableCell>
        <TableCell sx={sxNumberCell}>{evolution.total.evolution}%</TableCell>
      </>
    )}
  </TableRow>
));

export default StaffEvolutionListItem;
