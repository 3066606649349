import React, { useEffect, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

import { fetchStatistics } from '../../../../api/statistics';
import ProgressSection from '../../../../components/ProgressSection';
import { IStatistics } from '../../../../types/statistics.type';
import StatisticsDownloadButton from '../StatisticsDownloadButton';
import StatisticsEvolution from '../StatisticsEvolution';
import StatisticsStaff from '../StatisticsStaff';

const Statistics = React.memo(() => {
  const [isReady, setReady] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<IStatistics | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    fetchStatistics()
      .then((response) => setStatistics(response))
      .catch(() => setStatistics(null))
      .finally(() => setReady(true));
  }, []);

  const renderView = useMemo(() => {
    if (!isReady) {
      return <ProgressSection />;
    }

    if (!statistics) {
      return null;
    }

    return (
      <Grid container>
        <Grid item md={12} xl={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <StatisticsDownloadButton />
        </Grid>

        <Grid container id="statistics">
          <Grid item md={12}>
            <StatisticsStaff statistics={statistics.staff} />
          </Grid>

          <Grid item md={12}>
            <StatisticsEvolution
              title={t('dashboard.statistics.evolution')}
              statistics={statistics.evolutions}
            />
          </Grid>

          <Grid item md={12}>
            <StatisticsEvolution
              title={t('dashboard.statistics.evolution_week')}
              statistics={statistics.evolutions_week}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }, [isReady, statistics, t]);

  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {renderView}
    </Paper>
  );
});

export default Statistics;
