import React from 'react';

import { Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

interface IProps {
  title: string;
  type?: 'submit' | 'button';
  isLoading: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

const ProgressButton = React.memo(({ title, type, isLoading, isDisabled, onClick }: IProps) => (
  <Box sx={{ position: 'relative' }}>
    <Button
      type={type}
      color="darkBlue"
      variant="contained"
      disabled={isLoading || isDisabled}
      onClick={onClick}
    >
      {title}
    </Button>

    {isLoading && (
      <CircularProgress
        data-testid="circular-progress-button"
        size={24}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}
      />
    )}
  </Box>
));

export default ProgressButton;
