import React from 'react';

import Box from '@mui/material/Box';

interface IProps {
  children: React.ReactNode;
}
const Main = React.memo(({ children }: IProps) => (
  <Box
    component="main"
    sx={{
      backgroundColor: (theme) =>
        theme.palette.mode === 'light' ? '#e5e5e5' : theme.palette.grey[900],
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    }}
  >
    {children}
  </Box>
));

export default Main;
