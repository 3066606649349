import React, { useContext } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import UserContext from '../../user-context';
import UserInfo from './components/UserInfo';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  width: number;
}

interface IProps {
  open: boolean;
  width: number;
  handleToggle: () => void;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, width }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: width,
    width: `calc(100% - ${width}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = React.memo(({ open, handleToggle, width }: IProps) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <AppBar position="absolute" open={open} width={width}>
      <Toolbar
        sx={{
          pr: '24px',
          bgcolor: '#092948',
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleToggle}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          {t('header.title')}
        </Typography>

        {user && <UserInfo user={user} />}
      </Toolbar>
    </AppBar>
  );
});

export default Header;
