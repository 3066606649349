import React from 'react';

import { TableBody } from '@mui/material';

import { IInscription } from '../../../../types/inscription.type';
import InscriptionDetailsListItem from '../InscriptionDetailsListItem';

interface IProps {
  inscriptions: IInscription[];
}

const InscriptionDetailsListBody = React.memo(({ inscriptions }: IProps) => {
  const renderStaff = React.useMemo(() => {
    if (!inscriptions?.length) {
      return null;
    }

    return inscriptions.map((inscription) =>
      inscription
        .students!.map((student) => (
          <InscriptionDetailsListItem
            key={`student_${student.id}`}
            student={student}
            inscription={inscription}
          />
        ))
        .flat(),
    );
  }, [inscriptions]);

  return <TableBody>{renderStaff}</TableBody>;
});

export default InscriptionDetailsListBody;
