import React from 'react';

import { TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IStaffStudent } from '../../../../types/staff-student.type';
import { IOptions } from '../../../Staff/types';

interface IProps {
  student: IStaffStudent;
  options: IOptions;
}

const StaffStudentsItem = React.memo(({ student, options }: IProps) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>{student.school}</TableCell>
      <TableCell>{student.id}</TableCell>
      <TableCell>{student.lastName}</TableCell>
      <TableCell>{student.firstName}</TableCell>
      <TableCell>{student.parent.lastName}</TableCell>
      <TableCell>{student.parent.firstName}</TableCell>
      <TableCell>{student.parent.email}</TableCell>
      <TableCell>{student.schoolLevel}</TableCell>
      <TableCell>{t(`product_type.${student.productType.toLowerCase()}`)}</TableCell>
      <TableCell>{t(`general.${student.registrationType}_item`)}</TableCell>

      {options.displayPartnership && <TableCell>{student.partnership ?? ''}</TableCell>}

      <TableCell>{student.schoolYear}</TableCell>
      <TableCell>{student.status}</TableCell>
    </TableRow>
  );
});

export default StaffStudentsItem;
