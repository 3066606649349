import React from 'react';

import { TableCell, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { IRegistrationType } from '../../../../types/registration-type';
import { IStaffStatus } from '../../../../types/staff-status';
import { ArrayUtils } from '../../../../utils/array.utils';

interface IProps {
  school: string;
  statistics: IStaffStatus[];
  statuses: string[];
}

const sxNumberCell: SxProps<Theme> = { textAlign: 'right' };

const StaffStatusListItem = React.memo(({ school, statistics, statuses }: IProps) => {
  const { t } = useTranslation();

  const renderStatuses = React.useMemo(
    () =>
      statuses
        .map((status) => {
          const item = statistics.find((statisticsItem) => statisticsItem.code === status);

          return (
            <React.Fragment key={status}>
              <TableCell sx={sxNumberCell}>{item?.[IRegistrationType.new] ?? '-'}</TableCell>
              <TableCell sx={sxNumberCell}>{item?.[IRegistrationType.old] ?? '-'}</TableCell>
              <TableCell sx={sxNumberCell}>{item?.total ?? '-'}</TableCell>
            </React.Fragment>
          );
        })
        .filter(Boolean)
        .flat(),
    [statistics, statuses],
  );

  return (
    <TableRow>
      <TableCell>{t(`school.${school}`)}</TableCell>
      {renderStatuses}
      <TableCell sx={sxNumberCell}>{ArrayUtils.calculateTotal(statistics, 'total')}</TableCell>
    </TableRow>
  );
});

export default StaffStatusListItem;
