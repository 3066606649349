import React from 'react';

import { TableCell, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { IStaffStatistics } from '../../../../types/staff-statistics.type';

const sxNumberCell: SxProps<Theme> = { textAlign: 'center' };

interface IProps {
  item: IStaffStatistics;
}

const StaffStatisticsItem = React.memo(({ item }: IProps) => {
  const { t } = useTranslation();

  return (
    <React.Fragment key={`${item.groupCode}_${item.productType}`}>
      {item.levels
        .map((level) => (
          <TableRow key={`${item.groupCode}_${item.productType}_${level.schoolLevel}`}>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {t(`product_type.${item.productType.toLowerCase()}`)}
            </TableCell>
            <TableCell>{level.schoolLevel}</TableCell>

            {Object.entries(level.status).map(([name, status]) => (
              <TableCell
                key={`${item.groupCode}_${item.productType}_${level.schoolLevel}_${name}`}
                sx={sxNumberCell}
              >
                {status}
              </TableCell>
            ))}

            <TableCell sx={sxNumberCell}>{item.total}</TableCell>
          </TableRow>
        ))
        .flat()}
    </React.Fragment>
  );
});

export default StaffStatisticsItem;
