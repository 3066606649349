import React from 'react';

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid';
import { GridOwnProps } from '@mui/material/Grid/Grid';
import { SelectProps } from '@mui/material/Select/Select';
import { useTranslation } from 'react-i18next';

import { FORM_CONTROL_SX } from '../../constants/grid.constant';
import { ControlSx } from '../../types/control.type';
import { IFormSelectItem } from '../../types/form-select-item.type';

type IProps = SelectProps &
  ControlSx & {
    label: string;
    name: string;
    items: IFormSelectItem[];
    onChange: (event: SelectChangeEvent) => void;
    gridProps?: GridOwnProps;
    useEmptyValue?: boolean;
  };

const FormSelect = React.memo(
  ({
    useEmptyValue = false,
    label,
    items,
    onChange,
    name,
    controlSx = {},
    gridProps = {},
    ...props
  }: IProps) => {
    const { t } = useTranslation();

    return (
      <Grid item {...gridProps}>
        <FormControl
          sx={{
            ...FORM_CONTROL_SX,
            ...controlSx,
          }}
        >
          <InputLabel>{label}</InputLabel>

          <Select
            name={name}
            label={label}
            onChange={onChange}
            {...props}
            data-testid={`select-test-${name}`}
          >
            {useEmptyValue && (
              <MenuItem value="">
                <em>{t('general.all')}</em>
              </MenuItem>
            )}

            {items.map((item) => (
              <MenuItem key={item.code} value={item.code}>
                {item.name ?? item.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  },
);

export default FormSelect;
