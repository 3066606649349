import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import StaffFilters from './components/StaffFilters';
import StaffList from './components/StaffList';
import { IFilters, IOptions } from './types';
import { downloadStaff, fetchStaff } from '../../api/staff';
import ContentWrapper from '../../components/ContentWrapper';
import ProgressSection from '../../components/ProgressSection';
import { IStaff } from '../../types/staff.type';
import { HttpUtils } from '../../utils/http.utils';

const Staff = React.memo(() => {
  const [staff, setStaff] = useState<IStaff[]>([]);
  const [isSubmitProcess, setSubmitProcess] = useState<boolean>(false);
  const [isExportProcess, setExportProcess] = useState<boolean>(false);
  const [isReady, setReady] = useState<boolean>(false);
  const [options, setOptions] = useState<IOptions>({
    displayPartnership: false,
  });

  const { t } = useTranslation();

  const handleSubmit = React.useCallback(
    (filters: IFilters) => {
      setSubmitProcess(true);

      setOptions({
        ...options,
        displayPartnership: filters.partnerships.length > 0,
      });

      if (!isReady) {
        setReady(true);
      }

      fetchStaff(filters)
        .then((response) => {
          setStaff(response);
        })
        .catch(() => setStaff([]))
        .finally(() => setSubmitProcess(false));
    },
    [isReady, options],
  );

  const handleExport = React.useCallback(
    (filters: IFilters) => {
      setExportProcess(true);

      if (!isReady) {
        setReady(true);
      }

      downloadStaff(filters)
        .then((response) => HttpUtils.handleBlob(response, 'staff.xlsx'))
        .finally(() => setExportProcess(false));
    },
    [isReady],
  );

  const renderList = React.useMemo(() => {
    if (!isReady) {
      return null;
    }

    if (isSubmitProcess) {
      return <ProgressSection mt={3} />;
    }

    return <StaffList staff={staff} options={options} />;
  }, [staff, isReady, isSubmitProcess, options]);

  return (
    <ContentWrapper title={t('staff.title')}>
      <StaffFilters
        onSubmit={handleSubmit}
        onExport={handleExport}
        isSubmitProcess={isSubmitProcess}
        isExportProcess={isExportProcess}
      />

      {renderList}
    </ContentWrapper>
  );
});

export default Staff;
