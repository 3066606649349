import React from 'react';

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useTranslation } from 'react-i18next';

import Statistics from './components/Statistics';
import ContentWrapper from '../../components/ContentWrapper';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const Dashboard = React.memo(() => {
  const { t } = useTranslation();

  return (
    <ContentWrapper title={t('dashboard.title')}>
      <Statistics />
    </ContentWrapper>
  );
});

export default Dashboard;
