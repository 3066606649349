import React, { useEffect } from 'react';

import { TableCell } from '@mui/material';

import TableGroupRow from '../../../../components/TableGroupRow';
import { IInscription } from '../../../../types/inscription.type';
import { IProductType } from '../../../../types/product-type.type';
import { ArrayUtils } from '../../../../utils/array.utils';

interface IProps {
  groupName: string;
  productType: IProductType;
  inscriptions: IInscription[];
}

const InscriptionListGroup = React.memo(({ groupName, productType, inscriptions }: IProps) => {
  const [total, setTotal] = React.useState<number>(0);

  useEffect(() => {
    const calculatedTotal = ArrayUtils.calculateTotal(inscriptions, 'quantity');

    setTotal(calculatedTotal);
  }, [inscriptions, total]);

  return (
    <TableGroupRow
      groupTitle={groupName}
      groupRowColSpan={productType === IProductType.DISE ? 6 : 4}
    >
      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>{total}</TableCell>
    </TableGroupRow>
  );
});

export default InscriptionListGroup;
