import React from 'react';

import { Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';

import { a11yProps } from '../../../../theme';
import { IProductType } from '../../../../types/product-type.type';

interface IProps {
  activeTab: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  types: IProductType[];
}

const InscriptionTabs = React.memo(({ types, activeTab, onChange }: IProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={activeTab} onChange={onChange} aria-label="basic tabs example">
        {types.map((type, i) => (
          <Tab
            key={`tab_${type}`}
            label={t(`product_type.${type.toLowerCase()}`)}
            {...a11yProps(i)}
          />
        ))}
      </Tabs>
    </Box>
  );
});

export default InscriptionTabs;
