import React from 'react';

import { TableCell, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import { ISummaryStatistics } from '../../../../types/summary.type';

interface IProps {
  statistics: ISummaryStatistics;
}

const sxCell: SxProps<Theme> = { textAlign: 'center' };

const PreInscriptionSummaryListFraction = React.memo(({ statistics }: IProps) => (
  <TableRow>
    <TableCell sx={sxCell}>{statistics.responseDa.acceptedFraction}%</TableCell>
    <TableCell sx={sxCell}>{statistics.responseDa.refusedFraction}%</TableCell>
    <TableCell sx={sxCell}>100%</TableCell>
    <TableCell sx={sxCell}>{statistics.afterRapo.acceptedFraction}%</TableCell>
    <TableCell sx={sxCell}>{statistics.afterRapo.refusedFraction}%</TableCell>
    <TableCell sx={sxCell}>100%</TableCell>
    <TableCell sx={sxCell}>{statistics.responseRapo.acceptedFraction}%</TableCell>
    <TableCell sx={sxCell}>{statistics.responseRapo.refusedFraction}%</TableCell>
  </TableRow>
));

export default PreInscriptionSummaryListFraction;
