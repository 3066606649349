import React from 'react';

import {
  IPreInscriptionAcademy,
  IPreInscriptionSituationItemFields,
} from '../../../../types/pre-inscription-academy.type';
import PreInscriptionAcademyListItem from '../PreInscriptionAcademyListItem';

interface IProps {
  group: IPreInscriptionSituationItemFields;
  academies: IPreInscriptionAcademy[];
}

const PreInscriptionAcademyListGroup = React.memo(({ group, academies }: IProps) => (
  <>
    {academies.map((academy, idx) => (
      <PreInscriptionAcademyListItem
        group={group}
        academy={academy}
        isFirst={idx === 0}
        totalAcademies={academies.length}
        key={`section_group_list_${academy.code}_${group}`}
      />
    ))}
  </>
));

export default PreInscriptionAcademyListGroup;
