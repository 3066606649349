import React from 'react';

import { TableCell, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import { IStaff } from '../../../../types/staff.type';
import { IOptions } from '../../types';

interface IProps {
  staff: IStaff;
  options: IOptions;
}

const sxNumberCell: SxProps<Theme> = { textAlign: 'right' };

const StaffItem = React.memo(({ staff, options }: IProps) => (
  <TableRow>
    <TableCell>{staff.school}</TableCell>
    <TableCell>{staff.schoolYear}</TableCell>
    {options.displayPartnership && <TableCell>{staff.partnership ?? ''}</TableCell>}
    <TableCell>{staff.schoolLevel}</TableCell>
    <TableCell sx={sxNumberCell}>{staff.COCO.new}</TableCell>
    <TableCell sx={sxNumberCell}>{staff.COCO.old}</TableCell>
    <TableCell sx={sxNumberCell}>{staff.COCO.total}</TableCell>
    <TableCell sx={sxNumberCell}>{staff.DISE.new}</TableCell>
    <TableCell sx={sxNumberCell}>{staff.DISE.old}</TableCell>
    <TableCell sx={sxNumberCell}>{staff.DISE.total}</TableCell>
    <TableCell sx={sxNumberCell}>{staff.CERTIFICATE.new}</TableCell>
    <TableCell sx={sxNumberCell}>{staff.CERTIFICATE.old}</TableCell>
    <TableCell sx={sxNumberCell}>{staff.CERTIFICATE.total}</TableCell>
    <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>{staff.total}</TableCell>
  </TableRow>
));

export default StaffItem;
