import React from 'react';

import { TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IInscription } from '../../../../types/inscription.type';
import { IStudent } from '../../../../types/user.type';

interface IProps {
  inscription: IInscription;
  student: IStudent;
}

const InscriptionDetailsListItem = React.memo(({ student, inscription }: IProps) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>{inscription.school}</TableCell>
      <TableCell>{student.id}</TableCell>
      <TableCell>{student.lastName}</TableCell>
      <TableCell>{student.firstName}</TableCell>
      <TableCell>{student.parent.lastName}</TableCell>
      <TableCell>{student.parent.firstName}</TableCell>
      <TableCell>{student.parent.email}</TableCell>
      <TableCell>{inscription.schoolLevel}</TableCell>
      <TableCell>{t(`product_type.${inscription.productType}`)}</TableCell>
      <TableCell>{inscription.schoolYear}</TableCell>
      <TableCell>{student.status}</TableCell>
    </TableRow>
  );
});

export default InscriptionDetailsListItem;
