import React, { useEffect, useMemo, useState } from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { IStaffStatus } from '../../../../types/staff-status';
import { ArrayUtils } from '../../../../utils/array.utils';
import StaffStatusListBody from '../StaffStatusListBody';
import StaffStatusListFooter from '../StaffStatusListFooter';
import StaffStatusListHead from '../StaffStatusListHead';

interface IProps {
  statistics: IStaffStatus[];
}

const StaffStatusList = React.memo(({ statistics }: IProps) => {
  const [statuses, setStatuses] = useState<string[]>([]);

  useEffect(() => {
    const uniqueStatuses = ArrayUtils.uniqueValuesByKey<IStaffStatus, string>(statistics, 'code');

    setStatuses(uniqueStatuses);
  }, [statistics]);

  const statusNames = useMemo(
    () =>
      statuses.map((status) => {
        const item = statistics.findLast(({ code }) => code === status);

        return item?.status ?? status;
      }),
    [statistics, statuses],
  );

  return (
    <Grid container mt={3}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <StaffStatusListHead statuses={statusNames} />
            <StaffStatusListBody statistics={statistics} statuses={statuses} />
            <StaffStatusListFooter statistics={statistics} statuses={statuses} />
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
});

export default StaffStatusList;
