import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { IProductType } from '../../../../types/product-type.type';

const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold', textAlign: 'center' } };

interface IProps {
  productType: IProductType;
}

const InscriptionListHead = React.memo(({ productType }: IProps) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow sx={sxTableHead}>
        <TableCell>{t(`general.school`)}</TableCell>
        <TableCell>{t(`general.school_year`)}</TableCell>
        <TableCell>{t(`general.product_type`)}</TableCell>
        <TableCell>{t(`general.school_level`)}</TableCell>

        {productType === IProductType.DISE && (
          <>
            <TableCell>{t(`inscriptions.discipline`)}</TableCell>
            <TableCell>{t(`inscriptions.taxon`)}</TableCell>
          </>
        )}

        <TableCell>{t(`inscriptions.quantity`)}</TableCell>
      </TableRow>
    </TableHead>
  );
});

export default InscriptionListHead;
