import React from 'react';

import { Avatar, Button, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';

import { User } from '../../../../model/user.model';
import UserMenu from '../UserMenu';

interface IProps {
  user: User;
}

const UserInfo = React.memo(({ user }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" spacing={1}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Tooltip title={user.fullName} placement="left">
          <Avatar sx={{ bgcolor: '#d91f0a' }}>{user.initials}</Avatar>
        </Tooltip>
      </Button>

      <UserMenu open={open} anchorEl={anchorEl} handleClose={handleClose} />
    </Stack>
  );
});

export default UserInfo;
