import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import FormHandleControl from '../../../../components/FormHandleControl';
import FormSchoolLevelSelect from '../../../../components/FormSchoolLevelSelect';
import FormSchoolSelect from '../../../../components/FormSchoolSelect';
import FormSelect from '../../../../components/FormSelect';
import FormSelectMultiple from '../../../../components/FormSelectMultiple';
import ProgressSection from '../../../../components/ProgressSection';
import { useFetchDefaultFiltersData } from '../../../../hooks/useFetchDefaultFiltersData';
import { useFetchInscriptionFiltersData } from '../../../../hooks/useFetchInscriptionFiltersData';
import { useFilters } from '../../../../hooks/useFilters';
import { IFilterList } from '../../../../types/filter-list.type';
import { IProductType } from '../../../../types/product-type.type';
import { IFiltersForm } from '../../types';

type IProps = IFilterList<IFiltersForm> & {
  productType: IProductType;
};

const InscriptionFilters = React.memo(
  ({ productType, onSubmit, onExport, isSubmitProcess, isExportProcess }: IProps) => {
    const { t } = useTranslation();

    const { schools, schoolLevels, schoolYears, disciplines, taxons, isLoading } =
      useFetchInscriptionFiltersData();

    const defaultFilters = useFetchDefaultFiltersData<IFiltersForm>('inscription');

    const { filters, setFilters, handleSelectChange, handleMultipleChoiceChange } =
      useFilters<IFiltersForm>(defaultFilters);

    useEffect(() => {
      if (isLoading || filters.schoolYear?.length || !schoolYears.length) {
        return;
      }

      setFilters({
        ...filters,
        schoolYear: schoolYears[schoolYears.length - 1].code,
      });
    }, [filters, isLoading, schoolYears, setFilters]);

    useEffect(() => {
      setFilters(defaultFilters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productType]);

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();

      if (isLoading) {
        return;
      }

      onSubmit(filters);
    };

    return (
      <Box>
        {isLoading && <ProgressSection />}

        {!isLoading && (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} direction="row" alignItems="center">
              <FormSchoolSelect
                onChange={handleSelectChange}
                schools={schools}
                value={filters.school}
              />

              <FormSelect
                label={t('general.school_year')}
                name="schoolYear"
                value={filters.schoolYear}
                items={schoolYears}
                onChange={handleSelectChange}
              />

              <FormSchoolLevelSelect
                value={filters.schoolLevel}
                schoolLevels={schoolLevels}
                onChange={(value) => handleMultipleChoiceChange('schoolLevel', value)}
              />

              {productType === IProductType.DISE && (
                <>
                  <FormSelectMultiple
                    name="discipline"
                    value={filters.discipline}
                    onChoose={(value) => handleMultipleChoiceChange('discipline', value)}
                    label={t('inscriptions.discipline')}
                    items={disciplines}
                  />

                  <FormSelectMultiple
                    name="taxon"
                    value={filters.taxon}
                    onChoose={(value) => handleMultipleChoiceChange('taxon', value)}
                    label={t('inscriptions.taxon')}
                    items={taxons}
                  />
                </>
              )}
            </Grid>

            <FormHandleControl
              isSubmitProcess={isSubmitProcess}
              isExportProcess={isExportProcess}
              isDisabled={!filters.schoolYear}
              onExport={() => onExport(filters)}
            />
          </form>
        )}
      </Box>
    );
  },
);

export default InscriptionFilters;
