import React from 'react';

import { useTranslation } from 'react-i18next';

import { downloadStatistics } from '../../../../api/statistics';
import ProgressButton from '../../../../components/ProgressButton';
import { HttpUtils } from '../../../../utils/http.utils';

const StatisticsDownloadButton = React.memo(() => {
  const { t } = useTranslation();

  const [isProcess, setProcess] = React.useState<boolean>(false);

  const handleExport = React.useCallback(() => {
    setProcess(true);

    downloadStatistics()
      .then((response) => HttpUtils.handleBlob(response, 'statistics.xlsx'))
      .finally(() => setProcess(false));
  }, []);

  return (
    <ProgressButton
      title={t('general.export')}
      isLoading={isProcess}
      isDisabled={isProcess}
      onClick={handleExport}
    />
  );
});

export default StatisticsDownloadButton;
