import React from 'react';

import { ISummaryStatistics } from '../../../../types/summary.type';
import PreInscriptionSummaryListFraction from '../PreInscriptionSummaryListFraction';
import PreInscriptionSummaryListItem from '../PreInscriptionSummaryListItem';

interface IProps {
  statistics: ISummaryStatistics;
  type: string;
  groupCode: string;
}

const PreInscriptionSummaryListSection = React.memo(({ groupCode, type, statistics }: IProps) => (
  <>
    <PreInscriptionSummaryListItem statistics={statistics} groupCode={groupCode} type={type} />
    <PreInscriptionSummaryListFraction statistics={statistics} />
  </>
));

export default PreInscriptionSummaryListSection;
