import React from 'react';

import { TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TableTotalFooter from '../../../../components/TableTotalFooter';
import { IInscription } from '../../../../types/inscription.type';
import { IProductType } from '../../../../types/product-type.type';
import { ArrayUtils } from '../../../../utils/array.utils';

interface IProps {
  productType: IProductType;
  inscriptions: IInscription[];
}

const InscriptionListFooter = React.memo(({ inscriptions, productType }: IProps) => {
  const { t } = useTranslation();

  const renderTotalRow = React.useMemo(
    () => (
      <>
        <TableCell sx={{ fontWeight: 'bold' }} colSpan={productType === IProductType.DISE ? 6 : 4}>
          {t(`general.total`)}
        </TableCell>

        <TableCell>{ArrayUtils.calculateTotal(inscriptions, 'quantity')}</TableCell>
      </>
    ),
    [inscriptions, productType, t],
  );

  return <TableTotalFooter>{renderTotalRow}</TableTotalFooter>;
});

export default InscriptionListFooter;
