import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold', textAlign: 'center' } };

interface IProps {
  statuses: string[];
}

const StaffStatusListHead = React.memo(({ statuses }: IProps) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow sx={sxTableHead}>
        <TableCell rowSpan={2}>{t(`general.school`)}</TableCell>
        {statuses.map((status) => (
          <TableCell key={status} colSpan={3}>
            {status}
          </TableCell>
        ))}
        <TableCell rowSpan={2}>{t(`general.total`)}</TableCell>
      </TableRow>

      <TableRow sx={sxTableHead}>
        {statuses.map((status) => (
          <React.Fragment key={`total_${status}`}>
            <TableCell>{t(`general.new`)}</TableCell>
            <TableCell>{t(`general.old`)}</TableCell>
            <TableCell>{t(`general.total`)}</TableCell>
          </React.Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
});

export default StaffStatusListHead;
