import { fetchGet } from './api';
import { ApiHelper } from './api.helper';
import { IStatistics } from '../types/statistics.type';

export const fetchStatistics = () => fetchGet<IStatistics>('statistics');

export const downloadStatistics = async (): Promise<Blob> => {
  const url = ApiHelper.makeUrl('statistics/export');

  const response = await fetch(url, ApiHelper.makeOptions());
  const data = response.blob();

  if (!response.ok) {
    const responseData = await response.json();

    throw new Error(await responseData.message);
  }

  return data;
};
