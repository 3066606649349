import React, { useContext, useEffect, useState } from 'react';

import { SelectChangeEvent } from '@mui/material';
import Paper from '@mui/material/Paper';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { updateUserSettings } from '../../api/user';
import ContentWrapper from '../../components/ContentWrapper';
import ProgressSection from '../../components/ProgressSection';
import { useFetchSettingsFormData } from '../../hooks/useFetchSettingsFormData';
import { IPreInscriptionDateToType } from '../../types/user.type';
import UserContext from '../../user-context';
import SettingsGeneralForm from './components/SettingsGeneralForm';
import SettingsPreInscriptionsForm from './components/SettingsPreInscriptionsForm';
import { ISettingsForm } from './types';

const Settings = React.memo(() => {
  const { user, setUser } = useContext(UserContext);

  const { schools, schoolYears, isLoading } = useFetchSettingsFormData();
  const { t } = useTranslation();

  const [isReady, setReady] = useState(false);

  const [filters, setFilters] = useState<ISettingsForm>({
    school: user?.settings?.filters?.school ?? '',
    schoolYear: user?.settings?.filters?.schoolYear ?? '',
    schoolYearComparison: user?.settings?.filters?.schoolYearComparison ?? '',
    dateFrom: dayjs(user?.settings?.filters?.dateFrom),
    dateFromComparison: dayjs(user?.settings?.filters?.dateFromComparison),
    preInscriptionSchoolYear: user?.settings?.filters?.preInscriptionSchoolYear ?? '',
    preInscriptionDateFrom: dayjs(user?.settings?.filters?.preInscriptionDateFrom),
    preInscriptionDateToType:
      user?.settings?.filters?.preInscriptionDateToType ?? IPreInscriptionDateToType.tomorrow,
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const defaultYear = schoolYears[schoolYears.length - 1].code;
    const defaultFilters = Object.assign(filters, {});

    if (schoolYears.length) {
      if (!filters.schoolYear?.length) {
        defaultFilters.schoolYear = defaultYear;
      }

      if (!filters.schoolYearComparison?.length) {
        defaultFilters.schoolYearComparison = defaultYear;
      }

      if (!filters.preInscriptionSchoolYear?.length) {
        defaultFilters.preInscriptionSchoolYear = defaultYear;
      }
    }

    setFilters(defaultFilters);
    setReady(true);
  }, [filters, isLoading, schoolYears]);

  useEffect(() => {
    if (!isReady) {
      return;
    }

    updateUserSettings(filters).then((settings) => {
      if (!user) {
        return;
      }

      user.settings = settings;

      setUser(user);
    });
  }, [filters, setUser, isReady, user]);

  const handleSelectChange = React.useCallback(
    <T = string,>(event: SelectChangeEvent<T>) => {
      const { name, value } = event.target;

      if (!Object.keys(filters).includes(name)) {
        return;
      }

      setFilters({
        ...filters,
        [name]: value,
      });
    },
    [filters],
  );

  const handleDateChange = React.useCallback(
    (name: string, value: Dayjs | null) => {
      if (!Object.keys(filters).includes(name)) {
        return;
      }

      setFilters({
        ...filters,
        [name]: value,
      });
    },
    [filters],
  );

  return (
    <ContentWrapper title={t('settings.title')}>
      {!isReady && (
        <Paper sx={{ p: 2 }}>
          <ProgressSection />
        </Paper>
      )}

      {isReady && (
        <form>
          <SettingsGeneralForm
            schools={schools}
            schoolYears={schoolYears}
            filters={filters}
            handleSelectChange={handleSelectChange}
            handleDateChange={handleDateChange}
          />

          <SettingsPreInscriptionsForm
            schoolYears={schoolYears}
            filters={filters}
            handleSelectChange={handleSelectChange}
            handleDateChange={handleDateChange}
          />
        </form>
      )}
    </ContentWrapper>
  );
});

export default Settings;
