import React, { useEffect } from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import FormDatePicker from '../../../../components/FormDatePicker';
import FormHandleControl from '../../../../components/FormHandleControl';
import FormProductTypeSelect from '../../../../components/FormProductTypeSelect';
import FormRegistrationTypeSelect from '../../../../components/FormRegistrationTypeSelect';
import FormSchoolLevelSelect from '../../../../components/FormSchoolLevelSelect';
import FormSchoolSelect from '../../../../components/FormSchoolSelect';
import FormSelect from '../../../../components/FormSelect';
import FormSelectMultiple from '../../../../components/FormSelectMultiple';
import PaperWrapper from '../../../../components/PaperWrapper';
import { useFetchDefaultFiltersData } from '../../../../hooks/useFetchDefaultFiltersData';
import { useFetchStaffFiltersData } from '../../../../hooks/useFetchStaffFiltersData';
import { useFilters } from '../../../../hooks/useFilters';
import { IFilterList } from '../../../../types/filter-list.type';
import { IFilters } from '../../types';

type IProps = IFilterList<IFilters> & {
  useStudentStatusFilter?: boolean;
};

const StaffFilters = React.memo(
  ({
    onSubmit,
    onExport,
    isSubmitProcess,
    isExportProcess,
    useStudentStatusFilter = false,
  }: IProps) => {
    const { t } = useTranslation();

    const { schools, schoolLevels, schoolYears, partnerships, statuses, isLoading } =
      useFetchStaffFiltersData(useStudentStatusFilter);

    const defaultFilters = useFetchDefaultFiltersData<IFilters>('staff');

    const {
      filters,
      setFilters,
      handleSelectChange,
      handleDateChange,
      handleMultipleChoiceChange,
      handleCheckboxChoose,
    } = useFilters<IFilters>(defaultFilters);

    useEffect(() => {
      if (isLoading || filters.schoolYear?.length || !schoolYears.length) {
        return;
      }

      setFilters({
        ...filters,
        schoolYear: schoolYears[schoolYears.length - 1].code,
      });
    }, [filters, isLoading, schoolYears, setFilters]);

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();

      if (isLoading) {
        return;
      }

      onSubmit(filters);
    };

    return (
      <PaperWrapper isLoading={isLoading}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} direction="row" alignItems="center">
            <FormSchoolSelect
              onChange={handleSelectChange}
              schools={schools}
              value={filters.school}
            />

            <FormProductTypeSelect value={filters.productType} onChange={handleSelectChange} />

            <FormRegistrationTypeSelect
              value={filters.registrationType}
              onChange={handleSelectChange}
            />

            <FormSelect
              label={t('general.school_year')}
              name="schoolYear"
              value={filters.schoolYear}
              items={schoolYears}
              onChange={handleSelectChange}
            />

            <FormDatePicker
              label={t('general.date_from')}
              value={filters.dateFrom}
              onChange={(value) => handleDateChange('dateFrom', value)}
            />

            <FormDatePicker
              label={t('general.date_to')}
              value={filters.dateTo}
              onChange={(value) => handleDateChange('dateTo', value)}
            />
          </Grid>

          <Grid container spacing={1} direction="row" alignItems="flex-start">
            <FormSchoolLevelSelect
              value={filters.schoolLevel}
              schoolLevels={schoolLevels}
              onChange={(value) => handleMultipleChoiceChange('schoolLevel', value)}
            />

            <FormSelectMultiple
              name="partnerships"
              value={filters.partnerships}
              onChoose={(value) => handleMultipleChoiceChange('partnerships', value)}
              label={t('general.partnerships')}
              items={partnerships}
              controlSx={{ width: 200 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="withoutPartnerships"
                    checked={filters.withoutPartnerships}
                    onChange={handleCheckboxChoose}
                  />
                }
                label={t('staff.content.without_partnerships')}
              />
            </FormSelectMultiple>

            {useStudentStatusFilter && (
              <Grid item>
                <FormControl sx={{ m: 1, minWidth: 200, width: 200 }}>
                  <InputLabel>{t('general.student_status')}</InputLabel>
                  <Select
                    name="studentStatus"
                    value={filters.studentStatus}
                    label={t('general.student_status')}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="">
                      <em>{t('general.all')}</em>
                    </MenuItem>

                    <MenuItem value="empty">
                      <em>{t('general.empty_status')}</em>
                    </MenuItem>

                    {statuses.map((status) => (
                      <MenuItem key={status.code} value={status.code}>
                        {status.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>

          <FormHandleControl
            isSubmitProcess={isSubmitProcess}
            isExportProcess={isExportProcess}
            isDisabled={!filters.schoolYear}
            onExport={() => onExport(filters)}
          />
        </form>
      </PaperWrapper>
    );
  },
);

export default StaffFilters;
