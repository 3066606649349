import { useEffect, useState } from 'react';

import { fetchSchools } from '../api/school';
import { fetchSchoolYears } from '../api/school-year';
import { ISchoolYear } from '../types/school-year.type';
import { ISchool } from '../types/school.type';

export const useFetchSettingsFormData = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [schools, setSchools] = useState<ISchool[]>([]);
  const [schoolYears, setSchoolYears] = useState<ISchoolYear[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      let schoolsData: ISchool[] = [];
      let years: ISchoolYear[] = [];

      try {
        schoolsData = await fetchSchools();
        years = await fetchSchoolYears();
      } catch {
        /* empty */
      }

      setSchools(schoolsData);
      setSchoolYears(years);
    };

    fetchData().finally(() => setLoading(false));
  }, []);

  return { schools, schoolYears, isLoading };
};
