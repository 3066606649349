import React, { useEffect } from 'react';

import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import FormDatePicker from '../../../../components/FormDatePicker';
import FormHandleControl from '../../../../components/FormHandleControl';
import FormProductTypeSelect from '../../../../components/FormProductTypeSelect';
import FormSchoolSelect from '../../../../components/FormSchoolSelect';
import FormSelect from '../../../../components/FormSelect';
import PaperWrapper from '../../../../components/PaperWrapper';
import { useFetchDefaultFiltersData } from '../../../../hooks/useFetchDefaultFiltersData';
import { useFetchStaffStatusFiltersData } from '../../../../hooks/useFetchStaffStatusFiltersData';
import { useFilters } from '../../../../hooks/useFilters';
import { IFilterList } from '../../../../types/filter-list.type';
import { IFilters } from '../../types';

const StaffStatusFilters = React.memo(
  ({ onSubmit, onExport, isSubmitProcess, isExportProcess }: IFilterList<IFilters>) => {
    const { t } = useTranslation();

    const { schools, schoolYears, isLoading } = useFetchStaffStatusFiltersData();

    const defaultFilters = useFetchDefaultFiltersData<IFilters>('staffStatus');

    const { filters, setFilters, handleSelectChange, handleDateChange } =
      useFilters<IFilters>(defaultFilters);

    useEffect(() => {
      if (isLoading || filters.schoolYear?.length || !schoolYears.length) {
        return;
      }

      setFilters({
        ...filters,
        schoolYear: schoolYears[schoolYears.length - 1].code,
      });
    }, [filters, isLoading, schoolYears, setFilters]);

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();

      if (isLoading) {
        return;
      }

      onSubmit(filters);
    };

    return (
      <PaperWrapper isLoading={isLoading}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} direction="row" alignItems="center">
            <FormSchoolSelect
              onChange={handleSelectChange}
              schools={schools}
              value={filters.school}
            />

            <FormSelect
              label={t('general.school_year')}
              name="schoolYear"
              value={filters.schoolYear}
              items={schoolYears}
              onChange={handleSelectChange}
            />

            <FormDatePicker
              label={t('general.date_from')}
              value={filters.dateFrom}
              onChange={(value) => handleDateChange('dateFrom', value)}
            />

            <FormDatePicker
              label={t('general.date_to')}
              value={filters.dateTo}
              onChange={(value) => handleDateChange('dateTo', value)}
            />

            <FormProductTypeSelect value={filters.productType} onChange={handleSelectChange} />
          </Grid>

          <FormHandleControl
            isSubmitProcess={isSubmitProcess}
            isExportProcess={isExportProcess}
            isDisabled={!filters.schoolYear}
            onExport={() => onExport(filters)}
          />
        </form>
      </PaperWrapper>
    );
  },
);

export default StaffStatusFilters;
