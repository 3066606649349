import React from 'react';

import { TableBody } from '@mui/material';

import {
  IPreInscriptionAcademy,
  IPreInscriptionSituationItemFields,
} from '../../../../types/pre-inscription-academy.type';
import PreInscriptionAcademyListGroup from '../PreInscriptionAcademyListGroup';

interface IProps {
  academies: IPreInscriptionAcademy[];
}

const groups: IPreInscriptionSituationItemFields[] = ['responseDa', 'afterRapo', 'responseRapo'];

const PreInscriptionAcademyListBody = React.memo(({ academies }: IProps) => (
  <TableBody>
    {groups.map((group) => (
      <PreInscriptionAcademyListGroup key={group} group={group} academies={academies} />
    ))}
  </TableBody>
));

export default PreInscriptionAcademyListBody;
