import React from 'react';

import { TableCell, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import { IStaffStatusEvolution } from '../../../../types/staff-status-evolution.type';

interface IProps {
  evolution: IStaffStatusEvolution;
}

const sxCell: SxProps<Theme> = { textAlign: 'center' };

const StaffStatusEvolutionListItem = React.memo(({ evolution }: IProps) => (
  <TableRow>
    <TableCell>{evolution.status}</TableCell>
    <TableCell sx={sxCell}>{evolution.quantityPeriod1}</TableCell>
    <TableCell sx={sxCell}>{evolution.quantityPeriod2}</TableCell>
    <TableCell sx={sxCell}>{evolution.evolution}%</TableCell>
  </TableRow>
));

export default StaffStatusEvolutionListItem;
