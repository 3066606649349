import React, { useEffect, useState } from 'react';

import { TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

import SummaryItem from '../../../../components/SummaryItem';
import { IPreInscriptionReason } from '../../../../types/pre-inscription-reason.type';
import { REASON_TOTAL_CODE } from '../../constants';

interface IProps {
  reasons: IPreInscriptionReason[];
}

const PreInscriptionsReasonList = React.memo((props: IProps) => {
  const [reasons, setReasons] = useState<IPreInscriptionReason[]>([]);
  const [total, setTotal] = useState<IPreInscriptionReason>();

  const { t } = useTranslation();

  useEffect(() => {
    setTotal(props.reasons.find((reason) => reason.code === REASON_TOTAL_CODE));
    setReasons(props.reasons.filter((reason) => reason.code !== REASON_TOTAL_CODE));
  }, [props.reasons]);

  return (
    <Grid container mt={3}>
      <TableContainer component={Paper}>
        {reasons.map((reason) => (
          <SummaryItem
            key={`pre_inscription_reason_${reason.code}`}
            title={reason.name}
            statistics={reason}
          />
        ))}

        {total && (
          <SummaryItem
            key="pre_inscription_reason_total"
            title={t('general.total')}
            statistics={total}
          />
        )}
      </TableContainer>
    </Grid>
  );
});

export default PreInscriptionsReasonList;
