import React from 'react';

import { TableCell, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import TableGroupRow from '../../../../components/TableGroupRow';
import { StaffEvolutionHelper } from '../../../../helpers/staff-evolution.helper';
import { IProductType } from '../../../../types/product-type.type';
import { IStaffEvolution } from '../../../../types/staff-evolution.type';

interface IProps {
  groupName: string;
  evolution: IStaffEvolution[];
  displayProductType?: string;
}

const sxNumberCell: SxProps<Theme> = { fontWeight: 'bold', textAlign: 'right' };

const StaffEvolutionListGroup = React.memo(
  ({ groupName, evolution, displayProductType }: IProps) => {
    const { coco, dise, certificate, total } = StaffEvolutionHelper.calculateTotal(evolution);

    return (
      <TableGroupRow groupTitle={groupName}>
        {(!displayProductType?.length || displayProductType === IProductType.COCO) && (
          <>
            <TableCell sx={sxNumberCell}>{coco.period1}</TableCell>
            <TableCell sx={sxNumberCell}>{coco.period2}</TableCell>
            <TableCell sx={sxNumberCell}>{coco.evolution}%</TableCell>
          </>
        )}

        {(!displayProductType?.length || displayProductType === IProductType.DISE) && (
          <>
            <TableCell sx={sxNumberCell}>{dise.period1}</TableCell>
            <TableCell sx={sxNumberCell}>{dise.period2}</TableCell>
            <TableCell sx={sxNumberCell}>{dise.evolution}%</TableCell>
          </>
        )}

        {(!displayProductType?.length || displayProductType === IProductType.CERTIFICATE) && (
          <>
            <TableCell sx={sxNumberCell}>{certificate.period1}</TableCell>
            <TableCell sx={sxNumberCell}>{certificate.period2}</TableCell>
            <TableCell sx={sxNumberCell}>{certificate.evolution}%</TableCell>
          </>
        )}

        {!displayProductType?.length && (
          <>
            <TableCell sx={sxNumberCell}>{total.period1}</TableCell>
            <TableCell sx={sxNumberCell}>{total.period2}</TableCell>
            <TableCell sx={sxNumberCell}>{total.evolution}%</TableCell>
          </>
        )}
      </TableGroupRow>
    );
  },
);

export default StaffEvolutionListGroup;
