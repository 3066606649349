import React from 'react';

import { FormControl } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

import { FORM_CONTROL_SX } from '../../constants/grid.constant';
import { ControlSx } from '../../types/control.type';

type IProps = ControlSx & {
  label?: string;
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
};

const FormDatePicker = React.memo(({ label, controlSx = {}, onChange, value }: IProps) => (
  <Grid item>
    <FormControl
      sx={{
        ...FORM_CONTROL_SX,
        ...controlSx,
      }}
      data-testid="date-picker-test"
    >
      <DatePicker label={label} value={value} onChange={onChange} />
    </FormControl>
  </Grid>
));

export default FormDatePicker;
