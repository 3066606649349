import React from 'react';

import { IPreInscriptionSummary } from '../../../../types/pre-inscription-summary.type';
import { IRegistrationType } from '../../../../types/registration-type';
import { ISummaryStatistics } from '../../../../types/summary.type';
import PreInscriptionSummaryListSection from '../PreInscriptionSummaryListSection';

interface IProps {
  inscription: IPreInscriptionSummary;
}

const types = [IRegistrationType.new, IRegistrationType.old, 'total'] as Array<
  keyof IPreInscriptionSummary
>;

const PreInscriptionSummaryListGroup = React.memo(({ inscription }: IProps) => (
  <>
    {types.map((key) => (
      <PreInscriptionSummaryListSection
        key={`summary_item_${key}`}
        groupCode={inscription.groupCode}
        statistics={inscription[key] as ISummaryStatistics}
        type={key}
      />
    ))}
  </>
));

export default PreInscriptionSummaryListGroup;
