import React from 'react';

import { TableBody } from '@mui/material';

import { StatusHelper } from '../../../../helpers/status.helper';
import { IStaffStatus } from '../../../../types/staff-status';
import StaffStatusListItem from '../StaffStatusListItem';

interface IProps {
  statistics: IStaffStatus[];
  statuses: string[];
}

const StaffStatusListBody = React.memo(({ statistics, statuses: uniqueStatuses }: IProps) => {
  const renderList = React.useMemo(() => {
    if (!statistics?.length) {
      return null;
    }

    const grouped = StatusHelper.groupStatisticsBySchool(statistics);

    return Object.entries(grouped)
      .map(([school, items]) => (
        <StaffStatusListItem
          key={school}
          school={school}
          statistics={items}
          statuses={uniqueStatuses}
        />
      ))
      .flat();
  }, [statistics, uniqueStatuses]);

  return <TableBody>{renderList}</TableBody>;
});

export default StaffStatusListBody;
