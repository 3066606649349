import React from 'react';

import { TableFooter, TableRow } from '@mui/material';

interface IProps {
  children: React.ReactNode;
}

const TableTotalFooter = React.memo(({ children }: IProps) => (
  <TableFooter>
    <TableRow sx={{ '& td:not(:first-of-type)': { textAlign: 'right', fontWeight: 'bold' } }}>
      {children}
    </TableRow>
  </TableFooter>
));

export default TableTotalFooter;
