import React from 'react';

import Grid from '@mui/material/Grid';

import { IStatisticsEvolution } from '../../../../types/statistics.type';
import StatisticsEvolutionChart from '../StatisticsEvolutionChart';

interface IProps {
  title: string;
  statistics: IStatisticsEvolution;
}

const StatisticsEvolution = React.memo(({ title, statistics }: IProps) => {
  const charts = Object.entries(statistics).map(([school, data]) => (
    <Grid key={`statistics_chart_${school}`} item md={3}>
      <StatisticsEvolutionChart school={school} data={data} />
    </Grid>
  ));

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ pl: 3, pr: 3 }}
    >
      <Grid item md={12} textAlign="center">
        <h4>{title}</h4>
      </Grid>

      {charts}
    </Grid>
  );
});

export default StatisticsEvolution;
