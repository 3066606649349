import React from 'react';

import { TableBody } from '@mui/material';

import { IPreInscription } from '../../../../types/pre-inscription.type';
import PreInscriptionListItem from '../PreInscriptionListItem';

interface IProps {
  inscriptions: IPreInscription[];
}

const PreInscriptionListBody = React.memo(({ inscriptions }: IProps) => (
  <TableBody>
    {inscriptions.map((inscription) => (
      <PreInscriptionListItem key={inscription.id} inscription={inscription} />
    ))}
  </TableBody>
));

export default PreInscriptionListBody;
