import React from 'react';

import Paper from '@mui/material/Paper';

import ProgressSection from '../ProgressSection';

interface IProps {
  children: React.ReactNode;
  isLoading?: boolean;
}

const PaperWrapper = React.memo(({ children, isLoading = false }: IProps) => (
  <Paper
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}
  >
    {isLoading && <ProgressSection />}

    {!isLoading && children}
  </Paper>
));

export default PaperWrapper;
