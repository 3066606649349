import React from 'react';

import { TableBody } from '@mui/material';

import { IPreInscriptionSummary } from '../../../../types/pre-inscription-summary.type';
import PreInscriptionSummaryListGroup from '../PreInscriptionSummaryListGroup';

interface IProps {
  inscriptions: IPreInscriptionSummary[];
}

const PreInscriptionSummaryListBody = React.memo(({ inscriptions }: IProps) => (
  <TableBody>
    {inscriptions.map((inscription) => (
      <PreInscriptionSummaryListGroup key={inscription.groupCode} inscription={inscription} />
    ))}
  </TableBody>
));

export default PreInscriptionSummaryListBody;
