import React from 'react';

import { TableBody } from '@mui/material';

import { IStaffStudent } from '../../../../types/staff-student.type';
import { IOptions } from '../../../Staff/types';
import StaffStudentsItem from '../StaffStudentsItem';

interface IProps {
  students: IStaffStudent[];
  options: IOptions;
}

const StaffStudentsListBody = React.memo(({ students, options }: IProps) => {
  const renderStudents = React.useMemo(() => {
    if (!students?.length) {
      return null;
    }

    return students.map((student) => (
      <StaffStudentsItem key={student.id} student={student} options={options} />
    ));
  }, [students, options]);

  return <TableBody>{renderStudents}</TableBody>;
});

export default StaffStudentsListBody;
