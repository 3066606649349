import dayjs from 'dayjs';

export class DateUtils {
  /**
   * Get current study year
   */
  static getCurrentYear(): number {
    let year = dayjs().year();

    if (dayjs().month() < 8) {
      year -= 1;
    }

    return year;
  }
}
