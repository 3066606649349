import React from 'react';

import { InputLabel, SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import FormDatePicker from '../../../../components/FormDatePicker';
import FormSelect from '../../../../components/FormSelect';
import { IFormSelectItem } from '../../../../types/form-select-item.type';
import { ISchoolYear } from '../../../../types/school-year.type';
import { IPreInscriptionDateToType } from '../../../../types/user.type';
import { ISettingsForm } from '../../types';

const formFieldOptions: SxProps = { m: 1, width: 250 };

interface IProps {
  filters: ISettingsForm;
  schoolYears: ISchoolYear[];
  handleSelectChange: <T = string>(event: SelectChangeEvent<T>) => void;
  handleDateChange: (name: string, value: Dayjs | null) => void;
}

const SettingsPreInscriptionsForm = React.memo(
  ({ filters, schoolYears, handleSelectChange, handleDateChange }: IProps) => {
    const { t } = useTranslation();

    const dateToTypes: IFormSelectItem[] = React.useMemo(
      () =>
        Object.values(IPreInscriptionDateToType).map((code) => ({
          code,
          name: t(`settings.date_to_types.${code}`),
        })),
      [t],
    );

    return (
      <Paper sx={{ p: 2, mt: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
          {t('settings.pre_inscriptions')}
        </Typography>

        <Grid container item md={6} direction="column">
          <FormSelect
            label={t('settings.school_year')}
            name="preInscriptionSchoolYear"
            value={filters.preInscriptionSchoolYear}
            items={schoolYears}
            onChange={handleSelectChange}
            gridProps={{
              container: true,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            displayEmpty
            controlSx={formFieldOptions}
          />

          <Grid container item justifyContent="space-between" alignItems="center">
            <InputLabel>{t('settings.date_from')}</InputLabel>

            <FormDatePicker
              value={filters.preInscriptionDateFrom}
              onChange={(value) => handleDateChange('preInscriptionDateFrom', value)}
              controlSx={formFieldOptions}
            />
          </Grid>

          <FormSelect
            label={t('settings.date_to_type')}
            name="preInscriptionDateToType"
            value={filters.preInscriptionDateToType}
            items={dateToTypes}
            onChange={handleSelectChange}
            displayEmpty
            controlSx={formFieldOptions}
          />
        </Grid>
      </Paper>
    );
  },
);

export default SettingsPreInscriptionsForm;
