import React from 'react';

import { TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

import SummaryItem from '../../../../components/SummaryItem';
import { IPreInscriptionSchool } from '../../../../types/pre-inscription-school.type';

interface IProps {
  statistics: IPreInscriptionSchool;
}

const PreInscriptionSchoolList = React.memo(({ statistics }: IProps) => {
  const { t } = useTranslation();

  return (
    <Grid container mt={3}>
      <TableContainer component={Paper}>
        {Object.keys(statistics).map((key) => (
          <SummaryItem
            key={`pre_inscription_school_${key}`}
            title={t(`${key === 'total' ? 'general' : 'school'}.${key}`)}
            statistics={statistics[key]}
          />
        ))}
      </TableContainer>
    </Grid>
  );
});

export default PreInscriptionSchoolList;
