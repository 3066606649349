import React from 'react';

import { TableCell, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import {
  IPreInscriptionAcademy,
  IPreInscriptionSituationItemFields,
} from '../../../../types/pre-inscription-academy.type';
import { StringUtils } from '../../../../utils/string.utils';

interface IProps {
  group: IPreInscriptionSituationItemFields;
  academy: IPreInscriptionAcademy;
  totalAcademies: number;
  isFirst: boolean;
}

const sxCell: SxProps<Theme> = { textAlign: 'center' };

const PreInscriptionAcademyListItem = React.memo(
  ({ academy, group, totalAcademies, isFirst }: IProps) => {
    const { t } = useTranslation();

    return (
      <TableRow key={`${group}_${academy.code.replace(' ', '').toLowerCase()}`}>
        {isFirst ? (
          <TableCell sx={sxCell} rowSpan={totalAcademies}>
            {t(`pre_inscriptions_academies.${StringUtils.camelToSnakeCase(group)}`)}
          </TableCell>
        ) : null}

        <TableCell sx={sxCell}>{academy.name}</TableCell>

        {academy.situations.map((situation) => (
          <TableCell sx={sxCell} key={`section_head_list_${StringUtils.toKey(situation.code)}`}>
            {situation.total}
          </TableCell>
        ))}

        <TableCell sx={sxCell}>
          {academy.situations.reduce((total, situation) => total + situation.total, 0)}
        </TableCell>

        {academy.situations.map((situation) => (
          <React.Fragment key={`section_list_${StringUtils.toKey(situation.code)}`}>
            <TableCell sx={sxCell}>{situation[group].total}</TableCell>
            <TableCell sx={sxCell}>{situation[group].accepted}</TableCell>
            <TableCell sx={sxCell}>{situation[group].refused}</TableCell>
            <TableCell sx={sxCell}>{situation[group].acceptedFraction}%</TableCell>
          </React.Fragment>
        ))}

        <TableCell sx={sxCell}>{academy.total[group].total}</TableCell>
        <TableCell sx={sxCell}>{academy.total[group].accepted}</TableCell>
        <TableCell sx={sxCell}>{academy.total[group].refused}</TableCell>
        <TableCell sx={sxCell}>{academy.total[group].acceptedFraction}%</TableCell>
      </TableRow>
    );
  },
);

export default PreInscriptionAcademyListItem;
