import React from 'react';

import { ChartData } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { CHART_COLOR } from '../../../../constants/chart.constant';
import { PRODUCT_TYPE_LIST } from '../../../../constants/product-type.constant';
import { IStatisticsStaffItem } from '../../../../types/statistics.type';

interface IProps {
  school: string;
  data: IStatisticsStaffItem;
}

const StatisticsStaffChart = React.memo(({ school, data }: IProps) => {
  const { t } = useTranslation();

  const chartData: ChartData<'pie'> = {
    labels: PRODUCT_TYPE_LIST,
    datasets: [
      {
        label: ' ',
        data: PRODUCT_TYPE_LIST.map((type) => data[type]).flat(),
        backgroundColor: [
          CHART_COLOR.red.background,
          CHART_COLOR.yellow.background,
          CHART_COLOR.green.background,
        ],
        borderColor: [CHART_COLOR.red.border, CHART_COLOR.yellow.border, CHART_COLOR.green.border],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Pie
      data-testid="chart-pie"
      data={chartData}
      options={{
        plugins: {
          title: {
            display: true,
            text: t(`school.${school}`),
            align: 'center',
          },
        },
      }}
    />
  );
});

export default StatisticsStaffChart;
