import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { IInscription } from '../../../../types/inscription.type';
import InscriptionDetailsListBody from '../InscriptionDetailsListBody';
import InscriptionDetailsListHead from '../InscriptionDetailsListHead';

interface IProps {
  inscriptions: IInscription[];
}

const InscriptionDetailsList = React.memo(({ inscriptions }: IProps) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <InscriptionDetailsListHead />
          <InscriptionDetailsListBody inscriptions={inscriptions} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

export default InscriptionDetailsList;
