import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { IStaffStudent } from '../../../../types/staff-student.type';
import { IOptions } from '../../../Staff/types';
import StaffStudentsListBody from '../StaffStudentsListBody';
import StaffStudentsListHead from '../StaffStudentsListHead';

interface IProps {
  students: IStaffStudent[];
  options: IOptions;
}

const StaffStudentsList = React.memo(({ students, options }: IProps) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <StaffStudentsListHead options={options} />
          <StaffStudentsListBody students={students} options={options} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

export default StaffStudentsList;
